.productItem{
    background-color: white;
    margin:10px;
    padding: 10px;
    width: 400px;
    display:flex;
    flex-direction: column;
    gap: 10px;
    
}

.productItemEnquire{
    background-color: #FEBE10;
    padding: 10px;
    outline:none;
    border:none;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}

.productItemBuy{
    background-color: #FFD700;
    padding: 10px;
    outline:none;
    border:none;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}
