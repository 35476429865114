.cartPage {
    background-color: blue;
    flex: 0.7
}

.cartRightContainer {
    background-color: orange;
    flex: 0.3
}

.cartLeftContainer {
    display: flex;
    align-items: center;
    flex: 1
}